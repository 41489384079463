import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SalesHistory } from "../models/sales-history.model";
import { IDictionary } from "../shared/models/dictionary.model";
import { CDCService } from "./cdc.service";
import { ErrorService } from "./error.service";
import { LoadingService } from "./loading.service";
import { OrderService } from "./order.service";
import { TransactionService } from "./transaction.service";

export interface ISalesHistoryData {
  id: string;
  type: string;
  attributes: {
    typeCode: string;
    typeName: string;
    date: string;
    timestamp: string;
    salesChannelId: string;
    locationId?: string;
    locationName?: string;
    siteSourceId: string;
    siteSourceName: string;
    registerId?: string;
    cashierId?: string;
    customerId: string;
    customerReferenceId?: string;
    orderFulfillmentStatusCode?: string;
    orderFulfillmentStatusName?: string;
    //total: number; not implemented yet. Sum of line item’s totals (this doesn’t exist in the transactions response today - work with omni on the proper calculations)
  }
}

export enum TransactionSelections {
  all = "ALL",
  ecomm = "ECOMM",
  retail = "RETAIL"
}

export interface ISalesHistoryResponse {
  data: ISalesHistoryData[];
  links: {
    self: string;
  };
  meta: {
    correlationId: string;
    pageSize: number;
    pageBefore?: string;
    pageAfter?: string;
    totalObjects: number;
    //pageTotalObjects: number; not implemented yet, should equal page size expect last page
  }
}

@Injectable({
  providedIn: 'root',
})
export class SalesHistoryService {
  private _host = environment.apigee.host;
  public dateRange = new FormGroup({start: new FormControl, end: new FormControl});
  private _sales: BehaviorSubject<SalesHistory[]> = new BehaviorSubject<SalesHistory[]>([]);
  public sales$: Observable<SalesHistory[]> = this._sales.asObservable();
  private _clientSales: BehaviorSubject<IDictionary[]> = new BehaviorSubject<IDictionary[]>([]);
  public clientSales$: Observable<IDictionary[]> = this._clientSales.asObservable();
  public sales: SalesHistory[] = [];
  public totalSales: number = 0;
  public transactionType: TransactionSelections = TransactionSelections.all; //TODO: need to add this to url filter when able
  public salesListYAxis = 0;
  private _customerIds: string[] = [];
  private _next: string | undefined;
  private _prev: string | undefined;

  constructor(
    private _httpClient: HttpClient,
    private _errorService: ErrorService,
    private _loadingService: LoadingService,
    private _transactionService: TransactionService,
    private _orderService: OrderService,
    private _cdcService: CDCService
  ) {}

  public getInitialSalesHistory(ids: string[]): Observable<SalesHistory[] | null> {
    // this.customerIds = ["9003514800", "2895741"];
    if(this.customerIds.length > 12) {
      console.error('Customer had more than 12 ids.');
      return of(null);
    } else {
      let url = `${this._host}/customers/sales/history?filter[customerId]=${this.customerIds.join(
        ','
      )}&page[size]=10&sort=-date`;
      if (this.dateRange) {
        url += `&filter[date][ge]=${
            this.dateRange.value.start.toISOString().split('T')[0]
        }&filter[date][le]=${this.dateRange.value.end.toISOString().split('T')[0]
            }`;
      }
      return this.getSalesHistory(url);
    }
  }

    public getNextSalesHistory(): Observable<SalesHistory[]> {
      if (this._next) {
        return this.getSalesHistory(`${this._host}/customers/sales/history?page[after]=${this._next}`);
      } else {
        return of([]);
      }
    }
  
    // Not sure when we will use this
    public getPrevSalesHistory(): Observable<SalesHistory[]> {
      if (this._prev) {
        return this.getSalesHistory(`${this._host}/customers/sales/history?page[after]=${this._prev}`);
      } else {
        return of([]);
      }
    }

  public getSalesHistory(url): Observable<any> {
    //TODO: need to handle more than 2 years. most likely add it as a error check on date selector
    return this._httpClient.get<ISalesHistoryResponse>(url).pipe(
      mergeMap((sales: ISalesHistoryResponse) : Observable<any> => {
        if (sales.data.length === 0) {
          throw new Error('No sale history found');
        }
        const obs = sales.data.map((sale: ISalesHistoryData) => {
          const type = this._calculateType(sale);
          if (type === 'RETAILTRANSACTION') {
            //Transaction
            return this._transactionService.getTransactionById(sale.id, sale.attributes.registerId, sale.attributes.locationId, sale.attributes.date.replace(/-/g, '')).pipe(
              map((transactions) => {
                return new SalesHistory(sale, type, null, transactions);
              })
            );
          } else {
            //Order and Retail order
            return this._orderService.getOrderById(sale.id).pipe(
              mergeMap((orders) => {
                let order = orders.find((order) => order.orderId === sale.id && (order.type === "ECOMMORDER" || order.type === "RETAILORDER"))
                if(order.type === "RETAILORDER") {
                  //retail order
                  return this._transactionService.getTransactionByOrderId(order.saleChId).pipe(
                    mergeMap((transactions) => {
                      return of(new SalesHistory(sale, type, order, transactions));
                    })
                  );
                } else {
                  //Ecomm
                  return of(new SalesHistory(sale, type, order, null));
                }
              })
            );
          }
        });
        return forkJoin(obs).pipe(
          map((val) => {
            return {
              ...sales,
              data: val
            }
          })
        )
      }),
      map((result: any): SalesHistory[] => {
        this._next = result.meta.pageAfter;
        this._prev = result.meta.pageBefore;
        this.sales =
          this.sales.length > 0 ? this.sales.concat(result.data) : result.data;
        this._sales.next(this.sales);
        this.totalSales = result.meta.totalObjects;
        return result.data;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No sale history found') {
          this._errorService.showError('errors.ordersNoOrders');
        } else {
          this._errorService.showGeneralError(true);
        }
        this._loadingService.stopLoading();
        throw e;
      })
    );
  }


  public resetSalesHistory(): void {
    this._sales.next([]);
    this.sales = [];
    this._prev = undefined;
    this._next = undefined;
    this.dateRange = undefined;
    this.totalSales = 0;
    this.salesListYAxis = 0;
  }

  public get customerIds() {
    return this._customerIds;
  }

  public set customerIds(ids: string[]) {
    this._customerIds = ids;
  }

  private _calculateType(sale: ISalesHistoryData): string {
    if (sale.attributes.salesChannelId === 'RETAIL' && sale.type === 'orders') {
      return "RETAILORDER";
    } else if (sale.type === 'transactions') {
      return "RETAILTRANSACTION";
    } else {
      return "ECOMMORDER";
    }
  }

  public setClientSale() {
    const clientOrders = this.sales.map((sale) => {
          const client = this._cdcService.clients.find((client) =>
              client.ids.some((id) => id === sale.customerId)
          );
          const dict: IDictionary = {
            client: client,
            sale: sale,
          };
          return dict;
        }
    );
    this._clientSales.next(clientOrders)
  }
}