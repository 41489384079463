import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { SalesHistoryService } from '../../services/sales-history.service';

@Component({
  selector: 'order-search-page',
  templateUrl: './order-search-page.component.html',
  styleUrls: ['./order-search-page.component.scss'],
})
export class OrderSearchPageComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();

  constructor(
      private _salesHistoryService: SalesHistoryService,
      private _router: Router,
      private _msAuthService: MsAuthService,
      private _errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
        this._msAuthService.loggedIn$.subscribe((loggedIn: boolean) => {
          if (!loggedIn) {
            this._errorService.showError('errors.notLoggedIn');
            this._router.navigateByUrl(`/client-search`);
          }
        })
    );

    if (this._salesHistoryService.sales && this._salesHistoryService.sales.length === 1) {
      if(this._salesHistoryService.sales[0].type === 'transactions') {
        this._router.navigateByUrl(
          `/transaction/${this._salesHistoryService.sales[0].id}/${this._salesHistoryService.sales[0].registerId}/${this._salesHistoryService.sales[0].initiatingLocation}/${this._salesHistoryService.sales[0].date.replace('-', '')}`
        );
      } else {
        this._router.navigateByUrl(
          `/order/${this._salesHistoryService.sales[0].id}`
        );
      }
    }
    if (!this._salesHistoryService.sales || this._salesHistoryService.sales.length === 0) {
      this._router.navigateByUrl(`/client-search`);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public get totalOrders() {
    //TODO: need to add total orders
    return this._salesHistoryService.totalSales;
  }
}
