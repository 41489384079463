import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Transaction } from '../models/transaction.model';
import { LoadingService } from '../services/loading.service';
import { TransactionService } from '../services/transaction.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionResolver implements Resolve<Transaction[]> {
  // skipcq
  constructor(private _transactionService: TransactionService, private _loadingService: LoadingService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Transaction[]> {
    const orderId = route.paramMap.get('orderId'); 
    if (orderId) {
      this._loadingService.startLoading();
      return this._transactionService.getTransactionByOrderId(orderId).pipe(
        map((val) => {
          this._loadingService.stopLoading();
          return val;
        }),
        catchError((e) => {
          this._loadingService.stopLoading();
          console.log(e);
          return of(null);
        })
      );
    }

    const transactionId = route.paramMap.get('transactionId');
    const registerId = route.paramMap.get('registerId');
    const storeId = route.paramMap.get('storeId');
    const transactionDate = route.paramMap.get('transactionDate');
    this._loadingService.startLoading();
    return this._transactionService.getTransactionById(transactionId, registerId, storeId, transactionDate).pipe(
      map((val) => {
        this._loadingService.stopLoading();
        return val;
      }),
      catchError((e) => {
        this._loadingService.stopLoading();
        console.log(e);
        return of(null);
      })
    );
  }
}
